import {TAB} from '../../commons/constants/navigation'
import {isCalendarLayout} from '../../commons/selectors/settings'
import {getComponentConfig} from '../selectors/settings'
import {GetState} from '../types/state'
import {calendarSettingsSectionChanged, calendarSettingsTabChanged} from './calendar-layout'

export const SETTINGS_TAB_CHANGED = 'SETTINGS_TAB_CHANGED'

export const settingsTabChanged = (tab: TAB) => (dispatch: Function, getState: GetState) => {
  dispatch({
    type: SETTINGS_TAB_CHANGED,
    payload: tab,
  })

  if (isCalendarLayout(getComponentConfig(getState()))) {
    dispatch(calendarSettingsTabChanged(tab))
  }
}

export const settingsSectionChanged = (id: string) => (dispatch: Function, getState: GetState) => {
  if (isCalendarLayout(getComponentConfig(getState()))) {
    dispatch(calendarSettingsSectionChanged(id))
  }
}

// remove when merging specs.events.ui.ReactWidgetSettings
export enum SettingsTab {
  DISPLAY = 'display',
  LIST_DISPLAY = 'list-display',
}
