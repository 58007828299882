import {WEEKDAY} from '@wix/wix-events-commons-statics/dist/date/date'

export interface StyleParams {
  [key: string]: number | boolean
}

export interface ComponentConfig {
  settings: ComponentSettings
  params: StyleParams
  byStatus?: wix.events.editor.ByStatus
  byEventId?: wix.events.editor.ByEventId
  id: string
}

export interface ComponentSettings {
  widgetType: WIDGET_TYPE
  listLayout: number
  widgetLayout: number
  alignment: number
  contentAlignment: number
  listAlignment: number
  imagePosition: number
  verticalImagePosition: number
  imageGridPosition: number
  imageScaling: number
  imageRatio: number
  listImageLayout: number
  showImage: boolean
  cardsPerRow: number
  showCountdown: boolean
  showTitle: boolean
  showDescription: boolean
  showDate: boolean
  showLocation: boolean
  showShareOptions: boolean
  showFullDividers: boolean
  showHorizontalDividers: boolean
  showCompactDividers: boolean
  showLabels: boolean
  showRSVPTitle: boolean
  showMembers: boolean
  listShowMainTitle: boolean
  listShowDate: boolean
  listShowDayOfWeek: boolean
  listShowVenueName: boolean
  listShowFullDate: boolean
  listShowLocation: boolean
  listShowDescription: boolean
  listShowSocialShare: boolean
  listShowImage: boolean
  listShowAdditionalInfo: boolean
  listShowMembers: boolean
  locationAndDateFormat: number
  buttonsStyle: number
  borderWidth: number
  buttonsBorderWidth: number
  buttonsBorderRadius: number
  roundedButtonsBorderRadius: number
  buttonHeight: number
  oneButtonWidth: number
  verticalDividerWidth: number
  verticalDividerHeight: number
  horizontalDividerWidth: number
  horizontalDividerHeight: number
  compactHorizontalDividerWidth: number
  compactHorizontalDividerHeight: number
  countdownMarginBottom: number
  titleMarginBottom: number
  descriptionMarginBottom: number
  dateMarginBottom: number
  locationMarginBottom: number
  dateAndLocationMarginBottom: number
  rsvpMarginBottom: number
  imageWidth: number
  imageOpacity: number
  listButtonStyle: ButtonStyle
  cardMinWidth: number
  cardMargins: number
  cardBorderWidth: number
  sideBySideLayoutMargins: number
  listImagePosition: number
  listImageOpacity: number
  listImageWidth: number
  listLocationAndDateFormat: number
  listDividerWidth: number
  listStripWidth: number
  texts: ComponentSettingsTexts
  calendarWeekStartDay: WEEKDAY
  todayButtonStyle: ButtonStyle
  calendarCellBorderWidth: number
  membersEnabled: boolean
  showRibbon: boolean
  responsive: boolean
}

export interface ComponentSettingsTexts {
  time: string
  location: string
  RSVPTitle: string
  registrationClosedButtonText: string
  oneButton: string
  listButtonText: string
  listGeneralTitle: string
  todayButtonText: string
}

export enum ButtonStyle {
  FULL = 1,
  HOLLOW = 2,
  FULL_ROUNDED = 3,
  HOLLOW_ROUNDED = 4,
}

export enum IMAGE_RATIO {
  '1:1' = 1,
  '16:9' = 2,
}

export enum LIST_ALIGNMENT {
  LEFT = 1,
  CENTER = 2,
  RIGHT = 3,
}

export enum ALIGNMENT {
  LEFT = 1,
  CENTER = 2,
  RIGHT = 3,
}

export enum LIST_IMAGE_LAYOUT {
  LEFT = 1,
  MIXED = 2,
  RIGHT = 3,
}

export enum LIST_LOCATION_AND_DATE_FORMAT {
  COMPACT = 1,
  FULL = 2,
}

export enum LOCATION_AND_DATE_FORMAT {
  VERTICAL = 1,
  HORIZONTAL = 3,
  COMPACT = 2,
}

export enum WIDGET_TYPE {
  SINGLE = 1,
  LIST = 2,
}

export enum LIST_LAYOUT {
  LIST = 1,
  GRID = 2,
  NEW_GRID = 3,
  SIDE_BY_SIDE = 4,
  CALENDAR = 5,
}

export enum WIDGET_LAYOUT {
  FULL = 1,
  BUTTON_ONLY = 2,
  VERTICAL = 3,
  BACKGROUND = 4,
}

export enum OLD_WIDGET_LAYOUT {
  FULL = 'FULL',
  BUTTON_ONLY = 'BUTTON_ONLY',
  VERTICAL = 'VERTICAL',
  BACKGROUND = 'BACKGROUND',
  HORIZONTAL = 'HORIZONTAL', // deprecated
  RIGHT = 'RIGHT', // deprecated
}

export enum HORIZONTAL_IMAGE_POSITION {
  LEFT = 1,
  RIGHT = 2,
}

export enum VERTICAL_IMAGE_POSITION {
  TOP = 1,
  BOTTOM = 2,
}

export enum VERTICAL_ALIGNMENT {
  TOP = 1,
  CENTER = 2,
  BOTTOM = 3,
}

export enum IMAGE_SCALING {
  FIT = 1,
  CROP = 2,
}

export enum BUTTONS_STYLE {
  FULL = 1,
  HOLLOW = 2,
  FULL_ROUNDED = 3,
  HOLLOW_ROUNDED = 4,
}

export enum EVENT_FILTER_TYPE {
  MANUAL = 0,
  UPCOMING_AND_PAST = 1,
  UPCOMING = 2,
}

export enum IMAGE_ALIGNMENT {
  TOP_LEFT = 1,
  TOP_CENTER,
  TOP_RIGHT,
  CENTER_LEFT,
  CENTER,
  CENTER_RIGHT,
  BOTTOM_LEFT,
  BOTTOM_CENTER,
  BOTTOM_RIGHT,
}
