export enum TAB {
  MAIN = 'main',
  MY_EVENTS = 'events',
  LAYOUT = 'layout',
  DISPLAY = 'display',
  TEXT = 'texts',
  DESIGN = 'design',
  PAGES = 'pages',
  SUPPORT = 'support',
  UPGRADE = 'upgrade',
}

export enum PAGE {
  LANDING = 'landing',
}

export enum INNER_PAGE {
  DESIGN_TEXTS = 'design-texts',
  DESIGN_RIBBON = 'design-ribbon',
  DESIGN_BUTTONS = 'design-buttons',
  DESIGN_BACKGROUND = 'design-background',
  DESIGN_SPACES = 'design-spaces',

  IMAGE = 'image',
  TEXT_RSVP = 'text-rsvp',
  TEXT_RSVP_CLOSED = 'text-rsvp-closed',
  TEXT_TIME_AND_LOCATION = 'text-time-and-location',
  TEXT_TODAY = 'text-today',
}
