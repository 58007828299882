import {getLinguisticHeaderFromUrl} from '@wix/wix-events-commons-statics/dist/linguistic-header'
import {IWidgetControllerConfig} from 'native-components-infra/dist/src/types/types'

export const getLinguisticHeader = (controller: IWidgetControllerConfig) => {
  const {
    wixCodeApi: {
      window: {multilingual},
    },
    appParams: {
      instanceId,
      baseUrls: {iframeUrl},
    },
  } = controller

  if (multilingual.isEnabled && !iframeUrl) {
    const language = multilingual.siteLanguages.find(lang => {
      return lang.languageCode === multilingual.currentLanguage
    })

    return `${language.languageCode}|${language.locale}|${language.isPrimaryLanguage}|${instanceId}`
  }
  if (iframeUrl) {
    return getLinguisticHeaderFromUrl(iframeUrl)
  }
}

export const getMultilingualParams = (controller: IWidgetControllerConfig) => {
  const {
    wixCodeApi: {
      window: {multilingual},
    },
  } = controller

  if (multilingual.isEnabled) {
    const language = multilingual.siteLanguages.find(lang => {
      return lang.languageCode === multilingual.currentLanguage
    })
    return `&lang=${language.languageCode}&dateNumberFormat=${language.locale}&isPrimaryLanguage=${language.isPrimaryLanguage}`
  }
}
