import {getDateInTimezone, getStartOfDay, sameMonth} from '@wix/wix-events-commons-statics/dist/date/date'
import {EventType, RegistrationStatus} from '@wix/wix-events-commons-statics/dist/types/enums/events'
import * as _ from 'lodash'
import {getListButtonText, getOneButtonText, getRSVPClosedText, getWidgetType} from '../../commons/selectors/settings'
import {State} from '../types/state'
import {isForceShowRibbons} from './layout'
import {getComponentConfig, isRibbonVisible} from './settings'

export const getEventById = (state: State, eventId: string) => getEvents(state).find(event => event.id === eventId)

export const getEvents = (state: State): ExtendedEvent[] => {
  if (state.siteSettings.firstEventCreated || state.events.length) {
    return state.events
  }

  return state.demoEvents[getWidgetType(getComponentConfig(state))] as ExtendedEvent[]
}

export const getFirstEvent = (state: State) => getEvents(state)[0]

export const getEventsIds = (state: State) => getEvents(state).map(event => event.id)

export const getGroupedEventsByDate = (state: State, monthReferenceDate?: Date) => {
  const eventsMap: {[date: string]: ExtendedEvent[]} = {}

  getEvents(state).forEach(event => {
    const {startDate, timeZoneId} = event.scheduling.config

    if (startDate) {
      if (monthReferenceDate && !sameMonth(startDate, monthReferenceDate)) {
        return
      }

      const startDateString = getStartOfDay(getDateInTimezone(startDate, timeZoneId), true).toISOString()

      eventsMap[startDateString] = eventsMap[startDateString] || []
      eventsMap[startDateString].push(event)
    }
  })

  return eventsMap
}

const RIBBON_TEXTS = {
  [EventType.TICKETS]: {
    [RegistrationStatus.CLOSED]: 'ribbon.soldOut',
    [RegistrationStatus.CLOSED_MANUALLY]: 'ribbon.soldOut',
  },
  [EventType.RSVP]: {
    [RegistrationStatus.CLOSED]: 'ribbon.rsvpClosed',
    [RegistrationStatus.CLOSED_MANUALLY]: 'ribbon.rsvpClosed',
    [RegistrationStatus.OPEN_RSVP_WAITLIST]: 'ribbon.joinWaitList',
  },
}

const RIBBON_TEXT_FALLBACKS = {
  [EventType.TICKETS]: 'ribbon.soldOut',
  [EventType.RSVP]: 'ribbon.rsvpClosed',
}

const getRibbonTextFallback = (state: State, event) =>
  isForceShowRibbons(state) ? _.get(RIBBON_TEXT_FALLBACKS, event.registration.type, 'Sold Out') : ''

export const getRibbonText = (state: State, event: ExtendedEvent) =>
  isRibbonVisible(state)
    ? _.get(
        RIBBON_TEXTS,
        `${event.registration.type}.${event.registration.status}`,
        getRibbonTextFallback(state, event),
      )
    : ''

export const shouldShowRibbon = (state: State, event: ExtendedEvent) => Boolean(getRibbonText(state, event))

export const getRSVPButtonTextForSingleEvent = (state: State, event: ExtendedEvent) => {
  const componentConfig = getComponentConfig(state)
  return shouldShowRibbon(state, event) ? getRSVPClosedText(componentConfig) : getOneButtonText(componentConfig)
}

export const getRSVPButtonTextForEventListItem = (state: State, event: ExtendedEvent) => {
  const componentConfig = getComponentConfig(state)
  return shouldShowRibbon(state, event) ? getRSVPClosedText(componentConfig) : getListButtonText(componentConfig)
}
