import urijs from 'urijs'
import {Multilingual} from '../types/state'

export const getEventUrl = (pageUrl: string = '', event: wix.events.Event, multilingual: Multilingual) => {
  const url = new urijs(pageUrl)
  const path = url.path()
  let eventUrl = url.path(`${path}/${event.slug}`)

  if (multilingual.isEnabled) {
    const lang = multilingual.currentLanguage
    eventUrl = eventUrl.addQuery({lang})
  }

  return eventUrl.toString()
}
