import {GenericAPI} from '@wix/wix-events-commons-statics/dist/api/generic'
import {IUser} from 'native-components-infra/dist/es/src/types/types'
import {IWidgetControllerConfig} from 'native-components-infra/dist/src/types/types'
import {UPDATE_SITE_SETTINGS} from '../../commons/actions/site-settings'
import {instanceGetterFactory} from '../../commons/services/instance'
import {getLinguisticHeader, getMultilingualParams} from '../../commons/services/multilingual'
import {SiteSettingsSettings} from '../../commons/types/state'
import {getLanguage, getServerBaseUrl, getViewMode} from '../../commons/utils/wix-code-api'
import {UPDATE_COMPONENT, UPDATE_COMPONENT_DRAFT} from '../actions/component'
import {CREATE_EVENT} from '../actions/event'
import {LOAD_MEMBERS_FOR_EVENTS, PROMPT_LOGIN} from '../actions/members'

export class Api {
  api: GenericAPI
  registrar: any
  controller: IWidgetControllerConfig
  getInstance: () => string

  constructor(controller: IWidgetControllerConfig) {
    this.controller = controller
    this.getInstance = instanceGetterFactory(controller)
    this.api = new GenericAPI(getServerBaseUrl(controller.wixCodeApi), () => this.getHeaders(controller))

    this.registrar = {
      [UPDATE_COMPONENT.NAME]: this.updateComponent,
      [UPDATE_COMPONENT_DRAFT.NAME]: this.updateComponentDraft,
      [UPDATE_SITE_SETTINGS.NAME]: this.updateSiteSettings,
      [CREATE_EVENT.NAME]: this.createEvent,
      [PROMPT_LOGIN.NAME]: this.promptLogin,
      [LOAD_MEMBERS_FOR_EVENTS.NAME]: this.getGuestLists,
    }
  }

  get(name: string) {
    const api = this.registrar[name]

    if (api) {
      return api
    }

    throw `API METHOD IS NOT REGISTERED ${name}`
  }

  getHeaders = (controller: IWidgetControllerConfig) => {
    const linguisticHeader = getLinguisticHeader(controller)

    const headers = [
      ['Authorization', this.getInstance()],
      ['Content-Type', 'application/json'],
    ]

    if (linguisticHeader) {
      headers.push(['x-wix-linguist', linguisticHeader])
    }

    return headers
  }

  appendLinguisticParams = (path: string) => {
    const linguisticParams = getMultilingualParams(this.controller)
    return linguisticParams ? `${path}${linguisticParams}` : path
  }

  appendPetriOvr = (path: string) => {
    const {petri_ovr} = this.controller.wixCodeApi.location.query
    return petri_ovr ? `${path}&petri_ovr=${petri_ovr}` : path
  }

  getAppData = (members: boolean) => {
    const {compId, wixCodeApi} = this.controller
    const language = getLanguage(wixCodeApi)
    const viewMode = getViewMode(wixCodeApi)
    const url = `/html/widget-data?instance=${this.getInstance()}&compId=${compId}&locale=${language}&viewMode=${viewMode}&members=${members}`

    return this.api.get(this.appendLinguisticParams(this.appendPetriOvr(url)))
  }

  updateSiteSettings = (settings: SiteSettingsSettings) => {
    return this.api.put(`/web/site-settings`, {settings})
  }

  updateComponentDraft = (type: string, component: wix.events.editor.WebComponentConfig, members: boolean = false) => {
    const {compId} = this.controller
    return this.api.put(`/web/component/${compId}/draft?members=${members}`, {component})
  }

  updateComponent = (component: wix.events.editor.WebComponentConfig) => {
    const {compId} = this.controller
    return this.api.put(`/web/component/${compId}`, {component})
  }

  createEvent = (eventData: wix.events.EventData, ADI: boolean): Promise<{event: wix.events.Event; ADI: boolean}> => {
    return this.api.post('/adi/events', eventData).then(event => ({event, ADI}))
  }

  getGuestLists = (eventIds: string[]): Promise<GuestLists> =>
    this.api.get(`/web/members?eventIds=${JSON.stringify(eventIds)}`)

  promptLogin = (lang: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        await this.controller.wixCodeApi.user.promptLogin({
          mode: 'login',
          lang,
        })
        resolve(true)
      } catch (e) {
        if (typeof e !== 'string') {
          reject(e)
          return
        }
        resolve(false)
      }
    })
  }

  onLogin = (handler: LoginHandler) => {
    this.controller.wixCodeApi.user.onLogin(handler)
  }
}

export type LoginHandler = (user: IUser) => void
