import {EventMap} from '@wix/wix-events-commons-statics/dist/bi/interfaces'
import {AnyAction} from 'redux'
import {LIST_LAYOUT, WIDGET_TYPE} from '../../commons/constants/settings'
import {isDetailsPageEnabled} from '../../commons/selectors/site-settings'
import {WIDGET_LOADED} from '../actions/layout'
import {OPEN_MEMBERS_MODAL} from '../actions/members'
import {NAVIGATE_TO_PAGE} from '../actions/navigate-to-page'
import {State} from '../types/state'

export const eventMap: EventMap = {
  [NAVIGATE_TO_PAGE]: (state: State, action: AnyAction, {user}) => ({
    evid: 503,
    event_id: action.payload.event.id,
    destination_type: isDetailsPageEnabled(state.siteSettings) ? 'page_details' : 'page_form',
    visitor_id: user.uid || user.aid,
  }),
  [WIDGET_LOADED]: (state: State, {isHomePage}) => {
    const {widgetType, listShowImage, showImage, listLayout} = state.component.settings
    const single = widgetType === WIDGET_TYPE.SINGLE
    const display_type = single ? 'SINGLE' : 'LIST'

    const has_image = single ? showImage : listShowImage

    const layout = single ? 'SINGLE' : getListLayoutName(listLayout)

    return {
      evid: 80,
      has_image,
      layout,
      is_main_page: isHomePage,
      display_type,
    }
  },
  [OPEN_MEMBERS_MODAL]: (state: State, action: AnyAction, {user}) => ({
    evid: 89,
    event_id: action.payload.eventId,
    visitor_id: user.uid || user.aid,
  }),
  endpoint: 'events-uou',
}

const getListLayoutName = listLayout =>
  Object.entries(LIST_LAYOUT)
    .find(([, value]) => value === listLayout)
    .shift()
